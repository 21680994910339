<template>
  <div class="index">
    <div :class="['top', version == 2 && 'active']"></div>
    <div class="tabs">
      <van-icon v-if="fromUrl" name="arrow-left" class="backFrom" @click="backFrom" />
      <div
        :class="['left', (version == 1 && 'active') || '']"
        @click="version = 1"
      >
        标准版
      </div>
      <div
        :class="['right', (version == 2 && 'active') || '']"
        @click="
          version = 2;
          getHistory();
        "
      >
        专业版
        <!-- <span class="badage">付费</span> -->
        <img src="@/assets/pay.png" class="badage" alt="" />
      </div>
      <p class="combo" @click="buyCombe" v-show="version == 2">
        <img src="@/assets/combo.png" alt="" /> <span>购买套餐</span>
      </p>
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/banner1.png" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="wrapper" v-if="version == 1">
      <div class="list">
        <div class="col" @click="toQueryVin('queryVin')" v-if="source != 'pjs'">
          <img src="@/assets/vin.png" alt="" />
          <div>查VIN码</div>
        </div>
        <div class="col" @click="toQueryVin('queryPart')">
          <img src="@/assets/part.png" alt="" />
          <div>查零件号</div>
        </div>
        <div class="col" @click="toQueryVin('queryBrand')">
          <img src="@/assets/brand.png" alt="" />
          <div>查品牌件</div>
        </div>
        <div class="col" @click="toQueryVin('queryOrigin',1)">
          <img src="@/assets/oldBrand.png" alt="" />
          <div>查原厂件</div>
        </div>
      </div>
      <div class="tip">
        <img src="@/assets/freeText.png" alt="" />
        <div>
          每日免费查询 <span class="num">100</span> 次，当前剩余次数：
          <span class="num">{{ 100 - allQueryTimes }}</span>
        </div>
      </div>
      <div class="grid">
        <div class="title">汽车品牌</div>
        <van-grid :column-num="4" :border="false" :gutter="4">
          <van-grid-item
            v-for="(value, index) in carList"
            :class="[(activeNum.includes(index + 1) && 'active') || 'deActive']"
            :key="index"
            text="文字"
          >
            <div style="width: 30px; height: 30px">
              <img :src="value.img" style="width: 100%" alt="" />
            </div>
            <div>{{ value.name }}</div>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="grid">
        <div class="title">配件品牌</div>
        <van-grid :column-num="4" square :border="true">
          <van-grid-item v-for="(item, index) in partList" :key="index">
            <img :src="item.img" style="width: 100%" alt="" />
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div v-else class="indexQueryVin">
      <div class="search">
        <van-search
          v-model="vin"
          placeholder="请输入17位车架号"
          shape
          maxlength="17"
          :clearable="false"
        >
          <template #left-icon>
            <div style="visibility: hidden; width: 0"></div>
          </template>
          <template #right-icon>
            <div class="flex">
              <div class="icon">
                <van-uploader accept="image/*" :after-read="afterRead">
                  <van-icon name="photo-o" />
                </van-uploader>
              </div>
              <span class="line"></span>
              <div class="icon" @click="search"><van-icon name="search" /></div>
            </div>
          </template>
        </van-search>
      </div>
      <div class="overTimeMax" v-show="overTimeFlag">到期时间：{{overTimeMax}}(剩余{{overTimeDays}}天)</div>
      <div class="overTimeMax" style="font-size:12px" v-show="tryBuy && !overTimeFlag" @click="toTryUse">专业版不支持免费查询，点击试用>>> </div>
      <div style="height: 10px; background: #f8f8f8"></div>
      <div class="history flex">
        <div class="title">历史查询</div>
        <div class="clear" @click="clearHistory">
          <img src="@/assets/delete.png" alt="" style="width: 17px" />
        </div>
      </div>
      <div v-if="!isResult">
        <div v-if="history.length > 0">
          <div
            class="list"
            v-for="(item, index) in history"
            :key="index"
            @click="search(item.vin)"
          >
            <img :src="item.carLogoUrl" alt="" />
            <div class="name">
              <div>
                {{ item.modelName }}
              </div>
              <div class="flex">
                <div>{{ item.vin }}</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="text-align: center; margin: 80px auto">
          暂无历史记录
        </div>
      </div>
      <div v-else class="carConfig">
        <div class="title">车辆配置</div>
        <div v-if="list.length > 0">
          <div
            class="flex"
            v-for="(item, index) in list"
            :key="index"
            style="height: 30px; line-height: 30px"
          >
            <div v-show="index % 2 == 0">车型</div>
            <div v-show="index % 2 == 0">雪铁龙-C4 L-2013年</div>
            <div v-show="index % 2 > 0">传动系</div>
            <div v-show="index % 2 > 0">6速自动变速箱</div>
          </div>
        </div>
        <div v-else style="margin: 80px; text-align: center">
          该VIN码未解析到数据
        </div>
      </div>
    </div>
    <canvas style="display:none" id="canvas" class="canvas" width="0" height="0"></canvas>
  </div>
</template>

<script>
import {
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Dialog,
  Search,
  Icon,
  Uploader,
  Toast,
} from "vant";
import axios from "axios";
import { compress } from "@/utils/compress";
import {
  getEpcHistorySelect,
  deleteEpcHistorySelect,
  allSelectTimes,
  getBuyEsPackageList,
  addBuyEsPackage
} from "@/api";
import { getParams } from "@/utils/util";
export default {
  name: "index",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Uploader.name]: Uploader,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      active: 0,
      carList: [
        {
          img: require("@/assets/car/aodi.png"),
          name: "奥迪",
        },
        {
          img: require("@/assets/car/benchi.png"),
          name: "奔驰",
        },
        {
          img: require("@/assets/car/bentian.png"),
          name: "本田",
        },
        {
          img: require("@/assets/car/bmw.png"),
          name: "宝马",
        },
        {
          img: require("@/assets/car/dazhong.png"),
          name: "大众",
        },
        {
          img: require("@/assets/car/fengtian.png"),
          name: "丰田",
        },
        {
          img: require("@/assets/car/fute.png"),
          name: "福特",
        },
        {
          img: require("@/assets/car/kia.png"),
          name: "起亚",
        },
        {
          img: require("@/assets/car/lkss.png"),
          name: "雷克萨斯",
        },
        {
          img: require("@/assets/car/sikeda.png"),
          name: "斯柯达",
        },
        {
          img: require("@/assets/car/xiandai.png"),
          name: "现代",
        },
      ],
      partList: [
        { img: require("@/assets/part/BNX.png") },
        { img: require("@/assets/part/BS.png") },
        { img: require("@/assets/part/CY.png") },
        { img: require("@/assets/part/FLD.png") },
        { img: require("@/assets/part/FLS.png") },
        { img: require("@/assets/part/LN.png") },
        { img: require("@/assets/part/NGK.png") },
        { img: require("@/assets/part/SM.png") },
        { img: require("@/assets/part/TRW.png") },
        { img: require("@/assets/part/WET.png") },
      ],
      version: 1,
      vin: "",
      choiceCar: "奥迪 L B9 2019款 2.0T 7DCT DKU 三厢 40TFSI 运动型 国6",
      isResult: false, //识别成功标识
      list: [{}, {}, {}],
      history: [],
      activeNum: [], //背景颜色列表
      userId: "",
      allQueryTimes: "", //标准版查询次数
      source: "", //从哪个入口进入
      overTimeDays: '',
      overTimeMax: '',
      overTimeFlag: false,//true为套餐可用
      tryBuy: false,
      fromUrl: ''
    };
  },
  methods: {
    backFrom() {
      window.location.href = sessionStorage.getItem('fromUrl');
    },
    //图片上传
    afterRead(file) {
      if(130000 > file.file.size){
        this.doUpload(file.file)
      }else{
        compress(file.content, "canvas").then((file) => {
          this.doUpload(file);
        });
      }
    },
    doUpload(file){
      Toast.loading({
        message: "图片识别中...",
        overlay: true,
        forbidClick: true,
        duration: 0,
      });
      var that = this;
      var reader = new FileReader();
      console.log(file.size)
      reader.readAsDataURL(file); //转化二进制流，异步方法
      reader.onload = function () {
        //完成后this.result为二进制流
        var base64Str = this.result;
        axios({
          timeout: 6000,
          method: "POST",
          url: `https://vin.market.alicloudapi.com/api/predict/ocr_vin`,
          data: JSON.stringify({
            image: base64Str,
          }),
          headers: {
            "content-type": "application/octet-stream; charset=utf-8",
            Authorization: "APPCODE 6b312937f2fb4704ad37e582092af075",
          },
        })
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              that.vin = res.data.vin;
              // var reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
              // if (reg.test(res.data.vin)) {
              //   that.vin = res.data.vin;
              // } else {
              //   Dialog.alert({
              //     message: "识别到的VIN码格式不正确，请上传清晰的照片",
              //   });
              // }
              Toast.clear();
            } else {
              Toast.clear();
              Dialog.alert({
                message: "未识别到VIN码",
              });
            }
          })
          .catch((error) => {
            Toast.clear();
            if (error.message.includes("timeout")) {
              Dialog.alert({
                message: "请求超时",
              });
            } else {
              Dialog.alert({
                message: "未识别到VIN码或识别出错，请重试",
              });
            }
          });
      };
    },
    //查VIN码
    toQueryVin(path,type) {
      if (100 - this.allQueryTimes <= 0) {
        Dialog.alert({
          title: "提示",
          message: "今日免费次数已使用完，请明天再试",
        });
      } else {
        if(!type){
          this.$router.push("/" + path);
        }else{
          console.log(path,type)
          this.$router.push({name: path, params: { type}})
        }
      }
    },
    //购买套餐
    buyCombe() {
      this.$router.push("buyCombe");
    },
    //扫码按钮点击
    scan() {
      console.log("扫码");
    },
    //专业版次数查询
    getStandTimes() {
      getBuyEsPackageList({
        userId: this.userId,
        partyId: sessionStorage.getItem("partyId"),
      }).then(res=>{
        var {overTimeMax,overTimeDays,overTimeFlag} = res.data.data;
        var list = res.data.data.list || [];
        this.overTimeMax = overTimeMax;
        this.overTimeDays = overTimeDays;
        this.overTimeFlag = overTimeFlag;
        if(list.length <= 0){
          this.tryBuy = true;
        }
      })
    },
    //试用
    toTryUse() {
      addBuyEsPackage({
          epcSearchPackageId: 0,
          settleType: 2,
          userId: sessionStorage.getItem("userId"),
          partyId: sessionStorage.getItem("partyId"),
        }).then((res) => {
          console.log(res);
          this.getStandTimes();
          Dialog.alert({
            title: "提示",
            message:
              "您的7天免费试用套餐已激活，套餐余量可前往购买记录进行查看",
          });
        });
    },
    //搜索按钮点击
    search(vin) {
      getBuyEsPackageList({
        userId: this.userId,
        partyId: sessionStorage.getItem("partyId"),
        vin: this.vin,
      }).then((res) => {
        console.log(res);
        var total = res.data.data || {};
        var recordNum = res.data.data.list || [];
        var overTimeFlag = res.data.data.overTimeFlag;
        var message = "";
        if(recordNum.length <= 0){
          message = "专业版不支持免费VIN查询，请购买套餐后继续查询";
        }
        if (recordNum.length > 0 && !overTimeFlag) {
          message = "您的套餐已到期，请购买后再试";
        }
        if(message){
          Dialog.confirm({
            title: "提示",
            message,
            confirmButtonText: "立即购买",
            cancelButtonText: "取消",
          }).then(() => {
            this.$router.push({ name: "buyCombe" });
          });
          return;
        }
        if (vin && !vin.target) {
          this.vin = vin;
        }
        if (this.vin) {
          var reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
          if (!reg.test(this.vin)) {
            Toast.clear();
            Dialog.alert({ message: "VIN码格式不正确", title: "提示" });
            return;
          }
          this.$router.push({ name: "queryEPC", params: { vin: this.vin } });
        } else {
          Dialog.alert({ message: "请先输入vin码", title: "提示" });
        }
      });
    },
    //清空当前选择
    clearNow() {
      this.choiceCar = "";
      this.vin = "";
    },
    //清空历史记录
    clearHistory() {
      Dialog.confirm({
        title: "提示",
        message: "确定要删除历史记录吗？",
      }).then(() => {
        deleteEpcHistorySelect({
          userId: this.userId,
        }).then((res) => {
          this.history = [];
        });
      });
    },
    //获取历史记录
    getHistory() {
      getEpcHistorySelect({ userId: this.userId || getParams().userId }).then(
        (res) => {
          console.log(res.data.data.data);
          this.history = res.data.data.data || [];
        }
      );
    },
    //汽车品牌颜色显示计算
    getNum() {
      var active = [];
      var num = [...new Array(Math.ceil(this.carList.length / 4)).keys()];
      num.push(num.length);
      num.splice(0, 1);
      num.map((val) => {
        if (val % 2 == 0) {
          active.push((val - 1) * 4 + 2, (val - 1) * 4 + 4);
        } else {
          active.push((val - 1) * 4 + 1, (val - 1) * 4 + 3);
        }
      });
      this.activeNum = active;
    },
    //标准版查询剩余次数
    allSelectTimes() {
      allSelectTimes({ userId: this.userId }).then((res) => {
        console.log(res);
        this.allQueryTimes = res.data.data.timesCount;
      });
    },
  },
  mounted() {
    var { userId, partyId, fromUrl, source } = getParams();
    this.userId = userId;
    this.source = source;
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem("partyId", partyId);
    sessionStorage.setItem("source", source);
    sessionStorage.setItem("fromUrl", window.decodeURIComponent(fromUrl));
    this.fromUrl = window.decodeURIComponent(fromUrl);
    this.getNum();
    this.getStandTimes();
    this.allSelectTimes();
  },
  beforeRouteEnter(to, from, next) {
    console.log(window.location.href);
    if (from.name === "queryEPC" || from.name === "buyCombe") {
      next((vm) => {
        vm.version = 2;
        vm.getHistory();
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.index {
  /deep/.van-nav-bar {
    display: none;
  }
  .overTimeMax{
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: red;
  }
  .top {
    height: 183px;
    background: linear-gradient(to bottom, #1677ff, #2f99ff);
    width: 100%;
    border-radius: 0 0 10px 10px;
    &.active {
      background: linear-gradient(to right, #fbde98, #f6bc50);
    }
  }
  .tabs {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 50%;
    margin: 20px auto;
    display: flex;
    .backFrom{
      height: 35px;
      line-height: 35px;
      color: #fff;
      font-size: 25px;
      position: absolute;
      left: -75px;
    }
    div {
      width: 50%;
      height: 35px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
      &.left {
        border-radius: 10px 0 0 10px;
        background: #fff;
        color: #2f99ff;
      }
      &.right {
        border-left: 0px;
        border-radius: 0 10px 10px 0;
        background: #fff;
        position: relative;
        color: #2f99ff;
        .badage {
          position: absolute;
          right: 0;
          top: 0;
          width: 26px;
          // font-size: 10px;
          // font-weight: normal;
          // color: #9C683F;
          // color: #B95D1F;
          // background: linear-gradient(to bottom, #FEE32C, #FFE881);
          // border-radius: 0 10px 0 10px;
        }
      }
      &.active {
        background: linear-gradient(to bottom, #fbde98, #f7bb50, #f6c04f);
        color: #c47743;
      }
    }
    .combo {
      position: absolute;
      right: -65px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 19px;
        height: 16px;
      }
      span {
        color: #a18149;
        font-size: 12px;
      }
    }
  }
  .grid {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    .title {
      margin: 10px;
      font-size: 17px;
      color: #333333;
      font-weight: bold;
    }
    /deep/.van-grid-item.active {
      .van-grid-item__content {
        background: #fefdf9;
      }
    }
    /deep/.van-grid-item.deActive {
      .van-grid-item__content {
        background: #f9fafe;
      }
    }
  }
  .wrapper {
    padding: 45px 15px 0;
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 105px;
      .col {
        text-align: center;
        width: 105px;
        height: 105px;
        border-radius: 5px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        &:last-child {
          margin: 0;
        }
        img {
          width: 65px;
          height: 65px;
        }
      }
    }
    .tip {
      background: url(../assets/freeQuery.png) no-repeat 100%;
      background-size: contain;
      height: 45px;
      border-radius: 5px;
      border: 1px dashed #ccc;
      padding-left: 15px;
      margin: 10px 0;
      display: flex;
      align-items: center;
      color: #464646;
      font-size: 12px;
      img {
        width: 63px;
        margin-right: 10px;
      }
      .num {
        color: #ec1c00;
      }
    }
  }
  .my-swipe {
    position: absolute;
    top: 84px;
    left: 15px;
    right: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 139px;
    text-align: center;
  }
}
.indexQueryVin {
  .carConfig {
    padding: 10px 15px;
    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .list {
    display: flex;
    align-items: center;
    padding: 5px 15px 0;
    height: 80px;
    word-break: break-all;
    border-bottom: 1px solid #f4f4f4;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .name {
      color: #464646;
      .flex {
        color: #969696;
        display: flex;
        align-items: center;
      }
    }
  }
  .now {
    padding: 10px 15px;
    .car {
      word-break: break-all;
    }
    .clear {
      color: #fe9a23;
      margin-left: 10px;
    }
  }
  .history {
    margin-top: 10px;
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .clear {
      color: #fe9a23;
    }
  }
  .search {
    padding-top: 50px;
    .flex {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line {
        height: 20px;
        margin: 0 10px;
        width: 1px;
        background: #ccc;
      }
      .icon {
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .canvas {
    display: none;
  }
}
</style>